/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    // Chip,
    Divider,
    Drawer,
    Hidden,
    Link,
    List,
    ListSubheader,
    Typography,
    makeStyles
} from '@material-ui/core';
// import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
    ThumbsUp as ThumbsUpIcon,
    // Calendar as CalendarIcon,
    // ShoppingCart as ShoppingCartIcon,
    // Folder as FolderIcon,
    Settings as SettingsIcon,
    // BarChart as BarChartIcon,
    // Lock as LockIcon,
    // UserPlus as UserPlusIcon,
    // Shield as ShieldIcon,
    // AlertCircle as AlertCircleIcon,
    // Trello as TrelloIcon,
    // User as UserIcon,
    // Layout as LayoutIcon,
    // Edit as EditIcon,
    // DollarSign as DollarSignIcon,
    // Mail as MailIcon,
    MessageCircle as MessageCircleIcon,
    PieChart as PieChartIcon,
    // Share2 as ShareIcon,
    Users as UsersIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';

const navConfig = [
    {
        // subheader: 'Reports',
        items: [
            {
                title: 'Начало',
                icon: PieChartIcon,
                href: '/app/overview'
            }
        ]
    },
    {
        // subheader: 'Applications',
        items: [
            {
                title: 'Идеи',
                href: '/app/projects',
                icon: ThumbsUpIcon,
                items: [
                    {
                        title: 'Всички идеи',
                        href: '/app/ideas/browse'
                    }
                ]
            },
            {
                title: 'Обсъждания',
                icon: MessageCircleIcon,
                href: '/app/discussions',
                items: [
                    {
                        title: 'Всички обсъждания',
                        href: '/app/discussions'
                    },
                    {
                        title: 'Публикувай обсъждане',
                        href: '/app/discussions/create'
                    }
                ]
            },
            {
                title: 'Инициативи',
                icon: UsersIcon,
                href: '/app/actions',
                items: [
                    {
                        title: 'Всички инициативи',
                        href: '/app/actions/browse'
                    }
                ]
            }
        ]
    },
    {
        // subheader: 'Management',
        items: [
            {
                title: 'Настройки',
                icon: SettingsIcon,
                href: '/app/customers',
                items: [
                    {
                        title: 'Потребители',
                        href: '/app/users'
                    }
                ],
                roles: ['admin', 'super_user']
            }
        ]
    }
];

function renderNavItems({ items, ...rest }) {
    return (
        <List disablePadding>
            {items.reduce(
                (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
                []
            )}
        </List>
    );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
    const key = item.title + depth;

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false
        });

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                key={key}
                info={item.info}
                open={Boolean(open)}
                title={item.title}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                key={key}
                info={item.info}
                title={item.title}
            />
        );
    }

    return acc;
}

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    }
}));

function NavBar({ openMobile, onMobileClose }) {
    const classes = useStyles();
    const location = useLocation();
    const { user } = useSelector((state) => state.account);

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Hidden lgUp>
                    <Box p={2} display="flex" justifyContent="center">
                        <RouterLink to="/">
                            <Logo />
                        </RouterLink>
                    </Box>
                </Hidden>
                <Box p={2}>
                    <Box display="flex" justifyContent="center">
                        <RouterLink to="/app/account">
                            <Avatar
                                alt="User"
                                className={classes.avatar}
                                src={user.avatar}
                            />
                        </RouterLink>
                    </Box>
                    <Box mt={2} textAlign="center">
                        <Link
                            component={RouterLink}
                            to="/app/account"
                            variant="h5"
                            color="textPrimary"
                            underline="none"
                        >
                            {`${user.firstName} ${user.lastName}`}
                        </Link>
                        <Typography variant="body2" color="textSecondary">
                            {user.department}
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <Box p={2}>
                    {navConfig.map((config, idx) => {
                        const items = config.items.filter((i) =>
                            i.roles
                                ? i.roles.some((r) =>
                                      user.roles.map((r) => r.role).includes(r)
                                  )
                                : true
                        );

                        return items.length ? (
                            <List
                                // key={config.subheader}
                                key={idx}
                                subheader={
                                    <ListSubheader disableGutters disableSticky>
                                        {config.subheader}
                                    </ListSubheader>
                                }
                            >
                                {renderNavItems({
                                    items,
                                    pathname: location.pathname
                                })}
                            </List>
                        ) : null;
                    })}
                </Box>
                <Divider />
            </PerfectScrollbar>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
}

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default NavBar;
