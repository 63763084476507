import jwtDecode from 'jwt-decode';
// import axios from 'src/utils/axios';
import axios from 'src/utils/axios-prod';

// TODO: Simplify
const getUser = async (userId) => {
    try {
        const response = await axios.get(`/users/${userId}`);

        if (response.data) {
            const user = {
                department:
                    (response.data.member && response.data.member.name) || '—',
                role: 'admin', // TODO: Remove
                roles: response.data.roles || [],
                state: 'Габрово',
                country: 'България',
                ...response.data,
                avatar: `https://api.multiavatar.com/${response.data.id}.png` // TODO: Real pic
            };

            return user;
        }

        return response.error;
    } catch (error) {
        throw error;
    }
};

class AuthService {
    setAxiosInterceptors = ({ onLogout }) => {
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && error.response.status === 401) {
                    this.setSession(null);

                    if (onLogout) {
                        onLogout();
                    }
                }

                return Promise.reject(error);
            }
        );
    };

    handleAuthentication() {
        const accessToken = this.getAccessToken();

        if (!accessToken) {
            return;
        }

        if (this.isValidToken(accessToken)) {
            this.setSession(accessToken);
        } else {
            this.setSession(null);
        }
    }

    loginWithEmailAndPassword = async (username, password) => {
        // axios.post('/api/account/login', { email, password })
        try {
            const response = await axios.post('/auth/login', {
                email: username,
                password
            });

            const token = response.data && response.data.token;

            if (token) {
                this.setSession(token);
                const decoded = jwtDecode(token);
                const user = await getUser(decoded.id);

                return user;
            }
            throw response.data.error;
        } catch (error) {
            throw error;
        }
    };

    loginInWithToken = async () => {
        const accessToken = this.getAccessToken();
        const decoded = jwtDecode(accessToken);

        // axios.get('/api/account/me')
        return getUser(decoded.id);
    };

    logout = () => {
        this.setSession(null);
    };

    resetPassword = async (email) => {
        await axios
            .patch('/users/forgotPassword', {
                email
            })
            .catch((err) => {
                throw err;
            });
    };

    setSession = (accessToken) => {
        if (accessToken) {
            localStorage.setItem('accessToken', accessToken);
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        } else {
            localStorage.removeItem('accessToken');
            delete axios.defaults.headers.common.Authorization;
        }
    };

    getAccessToken = () => localStorage.getItem('accessToken');

    isValidToken = (accessToken) => {
        if (!accessToken) {
            return false;
        }

        const decoded = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000;

        return decoded.exp > currentTime;
    };

    isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
