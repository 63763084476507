import React, { useRef, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'src/utils/axios';
import {
    Avatar,
    Box,
    // Button,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover,
    SvgIcon,
    Tooltip,
    Typography,
    makeStyles
} from '@material-ui/core';
import {
    Bell as BellIcon,
    Package as PackageIcon,
    MessageCircle as MessageIcon,
    Truck as TruckIcon
} from 'react-feather';

const iconsMap = {
    order_placed: PackageIcon,
    new_message: MessageIcon,
    item_shipped: TruckIcon
};

const useStyles = makeStyles((theme) => ({
    popover: {
        width: 320
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText
    }
}));

const Notifications = () => {
    const classes = useStyles();
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { data: notifications = [] } = useQuery('notifications', async () => {
        const res = await axios.get('/api/notifications');

        return res.data && res.data.notifications;
    });

    return (
        <>
            <Tooltip title="Известия">
                <IconButton color="inherit" ref={ref} onClick={handleOpen}>
                    <SvgIcon>
                        <BellIcon />
                    </SvgIcon>
                </IconButton>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                classes={{ paper: classes.popover }}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <Box p={2}>
                    <Typography variant="h5" color="textPrimary">
                        Известия
                    </Typography>
                </Box>
                {notifications.length === 0 ? (
                    <Box p={2}>
                        <Typography variant="h6" color="textPrimary">
                            Няма нови известия
                        </Typography>
                    </Box>
                ) : (
                    <>
                        <List className={classes.list} disablePadding>
                            {notifications.map((notification) => {
                                const Icon = iconsMap[notification.type];

                                return (
                                    <ListItem
                                        className={classes.listItem}
                                        component={RouterLink}
                                        divider
                                        key={notification.id}
                                        to="#"
                                    >
                                        <ListItemAvatar>
                                            <Avatar className={classes.icon}>
                                                <SvgIcon fontSize="small">
                                                    <Icon />
                                                </SvgIcon>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={notification.title}
                                            primaryTypographyProps={{
                                                variant: 'subtitle2',
                                                color: 'textPrimary'
                                            }}
                                            secondary={notification.description}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                        {/* <Box
              p={1}
              display="flex"
              justifyContent="center"
            >
              <Button
                component={RouterLink}
                size="small"
                to="#"
              >
                Отбележи всички като прочетени
              </Button>
            </Box> */}
                    </>
                )}
            </Popover>
        </>
    );
};

export default Notifications;
