/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

const routesConfig = [
    {
        exact: true,
        path: '/',
        // component: () => <Redirect to="/home" />
        component: () => <Redirect to="/app" />
    },
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/pages/Error404View'))
    },
    {
        exact: true,
        guard: GuestGuard,
        path: '/login',
        component: lazy(() => import('src/views/auth/LoginView'))
    },
    {
        exact: true,
        path: '/login-unprotected',
        component: lazy(() => import('src/views/auth/LoginView'))
    },
    // {
    //     exact: true,
    //     guard: GuestGuard,
    //     path: '/register',
    //     component: lazy(() => import('src/views/auth/RegisterView'))
    // },
    // {
    //     exact: true,
    //     path: '/register-unprotected',
    //     component: lazy(() => import('src/views/auth/RegisterView'))
    // },
    {
        exact: true,
        guard: GuestGuard,
        path: '/password-recovery',
        component: lazy(() => import('src/views/auth/ForgotView'))
    },
    // {
    //     exact: true,
    //     path: '/password-recovery-unprotected',
    //     component: lazy(() => import('src/views/auth/ForgotView'))
    // },
    {
        path: '/app',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/app',
                component: () => <Redirect to="/app/overview" />
            },
            {
                exact: true,
                path: '/app/account',
                component: lazy(() => import('src/views/pages/AccountView'))
            },
            {
                exact: true,
                path: '/app/reports',
                component: () => <Redirect to="/app/reports/dashboard" />
            },
            {
                exact: true,
                path: '/app/customers',
                component: lazy(() =>
                    import('src/views/pages/CustomerListView')
                )
            },
            {
                exact: true,
                path: '/app/customers/:customerId',
                component: lazy(() =>
                    import('src/views/pages/CustomerDetailsView')
                )
            },
            {
                exact: true,
                path: '/app/customers/:customerId/edit',
                component: lazy(() =>
                    import('src/views/pages/CustomerEditView')
                )
            },
            // Settings > Users
            {
                exact: true,
                path: '/app/users',
                component: lazy(() => import('src/views/pages/UserListView'))
            },
            {
                exact: true,
                path: '/app/users/:customerId',
                component: lazy(() =>
                    import('src/views/pages/CustomerDetailsView')
                )
            },
            {
                exact: true,
                path: '/app/users/:customerId/edit',
                component: lazy(() =>
                    import('src/views/pages/CustomerEditView')
                )
            },
            // IDEAS
            {
                exact: true,
                path: '/app/overview',
                component: lazy(() => import('src/views/pages/OverviewView'))
            },
            {
                exact: true,
                path: '/app/ideas/browse',
                component: lazy(() => import('src/views/pages/IdeaBrowseView'))
            },
            {
                exact: true,
                path: '/app/ideas/create',
                component: lazy(() => import('src/views/pages/IdeaCreateView'))
            },
            {
                exact: true,
                path: '/app/ideas/:id',
                component: lazy(() => import('src/views/pages/IdeaDetailsView'))
            },
            {
                exact: true,
                path: '/app/ideas',
                component: () => <Redirect to="/app/ideas/browse" />
            },

            // DISCUSSIONS
            {
                exact: true,
                path: '/app/discussions',
                component: lazy(() =>
                    import('src/views/pages/DiscussionListView')
                )
            },
            {
                exact: true,
                path: '/app/discussions/create',
                component: lazy(() =>
                    import('src/views/pages/DiscussionCreateEditView')
                )
            },
            {
                exact: true,
                path: '/app/discussions/:id/edit',
                component: lazy(() =>
                    import('src/views/pages/DiscussionCreateEditView')
                )
            },
            {
                exact: true,
                path: '/app/discussions/:id',
                component: lazy(() =>
                    import('src/views/pages/DiscussionDetailsView')
                )
            },

            // Actions
            // IDEAS
            {
                exact: true,
                path: '/app/actions/overview',
                component: lazy(() => import('src/views/pages/OverviewView'))
            },
            {
                exact: true,
                path: '/app/actions/browse',
                component: lazy(() =>
                    import('src/views/pages/ActionBrowseView')
                )
            },
            {
                exact: true,
                path: '/app/actions/:id',
                component: lazy(() =>
                    import('src/views/pages/ActionDetailsView')
                )
            },
            {
                exact: true,
                path: '/app/actions',
                component: () => <Redirect to="/app/actions/browse" />
            },

            // Others
            {
                exact: true,
                path: '/app/management',
                component: () => <Redirect to="/app/customers" />
            },
            {
                component: () => <Redirect to="/404" />
            }
        ]
    },
    {
        path: '*',
        layout: MainLayout,
        routes: [
            {
                component: () => <Redirect to="/404" />
            }
        ]
    }
];

const renderRoutes = (routes) =>
    routes ? (
        <Suspense fallback={<LoadingScreen />}>
            <Switch>
                {routes.map((route, i) => {
                    const Guard = route.guard || Fragment;
                    const Layout = route.layout || Fragment;
                    const Component = route.component;

                    return (
                        <Route
                            key={i}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => (
                                <Guard>
                                    <Layout>
                                        {route.routes ? (
                                            renderRoutes(route.routes)
                                        ) : (
                                            <Component {...props} />
                                        )}
                                    </Layout>
                                </Guard>
                            )}
                        />
                    );
                })}
            </Switch>
        </Suspense>
    ) : null;

function Routes() {
    return renderRoutes(routesConfig);
}

export default Routes;
